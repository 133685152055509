<template>
	<S2SForm title="CIT Providers">
		<S2SCard title="Add CIT Provider">
			<S2SFormGenerator ref="citForm" :schema="citSchema"></S2SFormGenerator>
			<v-flex class="text-xs-left text-md-right">
				<v-btn text :to="{ name: 'cit-providers' }" class="mr-2">Cancel</v-btn>
				<v-btn color="accent" @click="onAdd" :disabled="status.loading">Add</v-btn>
			</v-flex>
		</S2SCard>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import citSchema from "@/static/config/forms/cit-schema.json";

export default Vue.extend({
	name: "CITProviderCreate",

	computed: {
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	data: function() {
		return {
			citSchema: citSchema
		};
	},

	methods: {
		async onAdd() {
			const form = this.$refs["citForm"];

			if (await form.validate()) {
				if (this.$store.dispatch("safes/createCITProvider", form.model)) {
					this.$router.push({ name: "cit-providers" });
				}
			}
		}
	}
});
</script>
